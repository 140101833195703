









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CardHint extends Vue {
  @Prop({ default: '$information' })
  private icon!: string

  @Prop({ default: '' })
  private text!: string

  @Prop({ default: false })
  private rounded!: boolean

  @Prop({
    default: 'blue',
    validator (value: string): boolean {
      return !!value.match(/(blue|orange)/)
    },
  })
  private color!: string
}
