































import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// Mixins
import MasterProgressMixin from '@/mixins/master/progress/MasterProgressMixin'
// Components
import Back from '@/components/_uikit/Back.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import MasterProgressModule from '@/store/modules/master/progress'
import ProgressCourseSidebar from '@/components/views/progress/course/ProgressCourseSidebar.vue'
import ProgressCourseContent from '@/components/views/progress/course/ProgressCourseContent.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MasterDashboardModule from '@/store/modules/master/dashboard'

@Component({
  components: {
    Back,
    LinkTabs,
    ProgressCourseContent,
    ProgressCourseSidebar,
  },
})
export default class ProgressCourse extends mixins(MasterProgressMixin, MasterGroupMixin) {
  private get userCourses() {
    return MasterProgressModule.coursesList.filter(course => course.hasGamification)
  }

  private get courseId() {
    return this.$route.params.courseID ? this.$route.params.courseID : ''
  }

  private currentIndexCourse = 0
  private isLoading = true

  private async mounted() {
    MasterProgressModule.fetchUserCourses()
      .catch(this.notifyError)

    this.fetchCourseStatistic()
  }

  private async fetchCourseStatistic() {
    try {
      await MasterProgressModule.fetchCourseProgressStatistic({ courseId: +this.courseId })
      await MasterProgressModule.fetchCourseRanksProgressStatistic(+this.courseId)
    } catch(e) {
      this.notifyError(e)
      this.$router.replace({ name: 'master.progress.dashboard' })
    } finally {
      this.isLoading = false
    }
  }

  private fetchDashboard(courseId: number) {
    MasterDashboardModule.fetchDashboard(courseId)
      .catch((error) => {
        if (error.response.status === 404) {
          this.notifyError('Курс не найден. Перезагрузите страницу')
        } else {
          this.notifyError(error)
        }
      })
  }

  private handleTabChange(masterGroupIndex: number) {
    this.currentIndexCourse = masterGroupIndex
  }

  @Watch('courseId')
  private watchCourseId() {
    this.fetchCourseStatistic()
  }
}
