




























import { Component, Prop, Vue } from 'vue-property-decorator'

// Components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
import ProgressStatsModal from '@/components/modals/progress/ProgressStatsModal.vue'
// Store
import MasterProgressModule from '@/store/modules/master/progress'

@Component({
  components: {
    CardWrapper,
    ProgressStatsModal,
    ProgressSubjectChip,
  },
})
export default class ProgressCourseSidebarStats extends Vue {
  @Prop({ default: 0 })
  percentCourseProgress!: number

  @Prop({ default: false })
  isCourseCompleted!: boolean

  private visible = false

  private get finalLevelDescription() {
    const array = MasterProgressModule.courseRanksProgress ?? []

    if (array.length) {
      return array[array.length - 1].description
    }

    return 'Per aspera ad astra. Поздравляем! Ты новый герой СМИТАП! Желаем удачи на экзаменах!'
  }

  private handleGoToStatistic() {
    this.visible = true
  }
}
