import { render, staticRenderFns } from "./ProgressCourseContent.vue?vue&type=template&id=00197b84&scoped=true&lang=pug&"
import script from "./ProgressCourseContent.vue?vue&type=script&lang=ts&"
export * from "./ProgressCourseContent.vue?vue&type=script&lang=ts&"
import style0 from "./ProgressCourseContent.vue?vue&type=style&index=0&id=00197b84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00197b84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCardText})
