














































import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// helpers
import { declension } from '@/utils/functions'
// mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// store
import { UserRankWithInfoResource } from '@/store/types/schema.progress'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    ProgressSubjectChip,
  },
})
export default class ProgressViewLevelModal extends MasterProgressCourseMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: null })
  private levelInfo!: UserRankWithInfoResource

  @Prop({ default: 0 })
  private countAvailableRanks!: number

  @Prop({ default: 37 })
  finalLevel!: number

  private get dialogWidth () {
    if (this.$vuetify.breakpoint.width > 1365) {
      return 600
    } else {
      return 500
    }
  }

  private localVisible = this.visible

  private handleClose() {
    this.localVisible = false
    this.$emit('closeModal')
  }

  private get rankImageSrc () {
    return this.getRankIcon(this.levelInfo.position, this.finalLevel === 2)
  }

  private declension (n: number, t: string[]) {
    return declension(n, t)
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value
  }
}
