









































import { Component, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

// Components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import MasterProgressModule from '@/store/modules/master/progress'
import { declension } from '@/utils/functions'

@Component({
  components: {
    CardWrapper,
  },
})
export default class ProgressCourseSidebarExercises extends Vue {
  private get courseID() {
    return this.$route.params.courseID
  }

  private get exercises() {
    if (MasterProgressModule.courseStatistic?.exercises) {
      return MasterProgressModule.courseStatistic.exercises.map((item) => {
        const date = DateTime.fromSQL(item.time)
        return {
          ...item,
          day: date.day,
          dayWeek: date.weekdayShort?.toLowerCase(),
          time: date.toFormat('HH:mm'),
        }
      })
    }

    return []
  }

  private get exercisesCounts () {
    return MasterProgressModule.courseStatistic?.exercisesCounts
  }

  private handleRedirectToExercise(exerciseUUID: string, groupID: string) {
    // const courseID = this.courseID.toString()

    this.$router.push({
      name: 'master.exercises.item',
      params: {
        exerciseUUID,
        groupID,
      },
    })
  }

  private get completedExercise() {
    if (MasterProgressModule.courseStatistic && MasterProgressModule.courseStatistic.exercisesCounts) {
      return MasterProgressModule.courseStatistic.exercisesCounts.current
    }
    return 0
  }

  private get toCompleteExercise() {
    if (MasterProgressModule.courseStatistic && MasterProgressModule.courseStatistic.exercisesCounts) {
      return MasterProgressModule.courseStatistic.exercisesCounts.all
    }
    return 0
  }

  private get remaingExerciseCount() {
    return this.toCompleteExercise - this.completedExercise
  }

  private declension (n: number, t: string[]) {
    return declension(n, t)
  }
}
