


























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import CardHint from '@/components/_uikit/CardHint.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'

interface explanationContent {
  text: string,
  title: string,
  type: 'text' | 'hint',
}

@Component({
  components: {
    CardHint,
    Dialog,
    ModalWrapper,
  },
})
export default class CourseExplanationPlanStudy extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: false })
  private isProgressView!: boolean

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private titleForMasters = 'Получение уровня'
  private contentForMasters: explanationContent[] = [
    {
      text: 'Это степень прогресса твоего обучения в рамках одного курса. Твоя скорость обучения на курсах может быть разная, но все курсы стартуют с первого уровня.',
      title: 'Что такое уровень?',
      type: 'text',
    },
    {
      text: 'Нужно выполнять действия, напрямую связанные с обучением, а именно: смотреть уроки и выполнять домашние задания. Для получения уровня важно количество, а не последовательность занятий в расписании.',
      title: 'Как получить уровень?',
      type: 'text',
    },
    {
      text: 'После просмотра урока в записи или онлайн на странице урока тебе нужно нажать кнопку <span class="blue--text">Я посмотрел урок</span>. После выполнения домашнего задания (творческое, вторая часть или тест) на странице задания тебе нужно будет нажать кнопку <span class="blue--text">Отправить</span>.',
      title: 'Как выполнять действия?',
      type: 'text',
    },
  ]

  private titleForMentor = 'План занятий для игрофикации'
  private contentForMentor = [
    {
      text: 'Игрофикация – это бонусная система поощрений для мастеров, в рамках которой они могут получать уровни за обучение на курсах. ',
      title: 'Что такое игрофикация?',
      type: 'text',
    },
    {
      text: 'Каждый следующий уровень достигается за выполнение определенных действий: просмотр N уроков и выполнение N заданий. Вычисление количества действий происходит <a href="https://docs.google.com/spreadsheets/d/1AIwQ4JhOIFhlMt-KHd4H2fximbvWhejU-x594j2_y0E/edit?usp=sharing" class="link blue--text" target="_blank">по формуле</a>. Для правильного расчета необходимо указать плановое количество занятий в курсе за все время его проведения.',
      title: 'Как получить уровень?',
      type: 'text',
    },
    {
      text: '',
      title: 'Значения в полях расписания нельзя менять у групп за месяц до окончания курса, у спецкурсов — за день до окончания.',
      type: 'hint',
    },
    {
      text: 'Курсы с типом <span class="font-weight-bold">Группа</span> рассчитаны на длительное время обучения и содержат 37 уровней для мастеров. Курсы с типом <span class="font-weight-bold">Спецкурс</span> — это короткие курсы с 2 уровнями. Минимальные значения плана для групп 36 уроков и заданий, для спецкурса — 1 урок и 1 задание.',
      title: 'Тип группы на курсе',
      type: 'text',
    },
    {
      text: 'Мастера не будут видеть уровни курса, игрофикация которого отключена. Чтобы это исправить, бонусную систему необходимо включить, но надо помнить об условиях игрофикации, которые подходят не для всех курсов. Отключить игрофикацию повторно будет невозможно. При включении игрофикации у начавшегося курса значения в полях плана должны быть рассчитаны с начала момента включения.',
      title: 'Наличие игрофикации',
      type: 'text',
    },
  ]

  private get explanationContent () {
    return this.isProgressView ? this.contentForMasters : this.contentForMentor
  }

  private get explanationTitle () {
    return this.isProgressView ? this.titleForMasters : this.titleForMentor
  }

  private get texts() {
    return {
      firstContent: this.isProgressView ? 'Это степень прогресса твоего обучения в рамках одного курса. Твоя скорость обучения на курсах может быть разная, но все курсы стартуют с уровня 1.' : 'Бонусная система поощерений для мастеров, в рамках которой они могут получать уровни за обучение на курсах.',
      secondContent: this.isProgressView ? 'Нужно выполнять действия, напрямую связанные с обучением, а именно смотреть уроки и выполнять домашние задания. Для получения уровня важно количество, а не последовательность занятий в расписании.' : 'Каждый следующий уровень достигается за выполнение определенных действий: просмотр N уроков и выполнение N заданий. Вычисление количества действий происходит по формуле. Для правильного рассчета необходимо указать плановое количество занятий в курсе за все время его проведения.',
      title: this.isProgressView ? 'Получение уровня' : 'План занятий в игрофикации',
    }
  }
}
