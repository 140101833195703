


































import { Component, Prop, Ref } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import CourseLevelCard from '@/components/cards/CourseLevelCard.vue'
// mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
import ScreenMixin from '@/mixins/ScreenMixin'
// types
import { CourseSubjectType } from '@/store/types/progress'

// TEMP
import { ILevel } from '../ProgressCourseContent.vue'

interface IPos {
  x: number,
  y: number,
}

export interface ICardRef {
  chunkNumber: number,
  rank: number,
  ref: HTMLDivElement,
  x: number,
  y: number,
}

@Component({
  components: {
    CardWrapper,
    CourseLevelCard,
  },
})
export default class ProgressCourseContentTree extends mixins(MasterProgressCourseMixin, ScreenMixin) {
  @Prop({ required: true })
  subject!: CourseSubjectType

  @Prop({ required: true })
  showAll!: boolean

  @Prop({ required: true })
  currentRank!: number

  @Prop({ required: true })
  chunkedLevels!: Array<ILevel[]>

  @Prop({ required: true })
  finalLevel!: ILevel

  @Prop({ default: false })
  isSpecialCourse!: boolean

  private refs: Array<ICardRef> = []
  private finalLevelRef: ICardRef | null = null

  private ctx: null | CanvasRenderingContext2D = null

  private lineCurrentPos: IPos = { x: 0, y: 0 }

  private isResize = false
  private observer = new ResizeObserver(size => this.handleResize(size[0].contentRect.height))


  @Ref() canvas !: HTMLCanvasElement
  @Ref() treeContainer !: HTMLDivElement

  private get classes() {
    return {
      ['short-tree']: this.chunkedLevels.flat().length <= 2 && !this.showAll,
      ['special-tree']: this.isSpecialCourse,
    }
  }

  private get isShowFinalLevel () {
    return (
      !this.isSpecialCourse
      && (this.finalLevel && (this.showAll || (this.finalLevel.rank === this.currentRank)))
    )
  }

  private get turningSize () {
    return this.$vuetify.breakpoint.width > 1439 ? 50 : 30
  }

  private get gridGapSize () {
    return this.$vuetify.breakpoint.width > 1439 ? 32 : 24
  }

  private get tagBottomOffset () {
    return this.$vuetify.breakpoint.width > 1439
      ? 16 + 12
      : 12 + 12
  }

  private get strokeSize () {
    return 5
  }

  // -------------------------------------------- Hooks --------------------------------------------
  // private created () {
  //   window.addEventListener('resize', this.handleResize.bind(this))
  // }

  private mounted() {
    this.$nextTick(() => {
      this.observer.observe(this.treeContainer)
    })
  }

  private beforeDestroy() {
    if (this.treeContainer)
      this.observer.unobserve(this.treeContainer)
  }

  // ------------------------------------------- System --------------------------------------------
  private handleResize(height: number) {
    if (this.treeContainer)
      this.treeContainer.style.setProperty('height', height + 'px')

    this.isResize = true
    this.refs = []
    this.finalLevelRef = null

    setTimeout(() => {
      this.isResize = false
      this.treeContainer?.style.removeProperty('height')
    })

    setTimeout(() => {
      if (this.canvas) {
        this.canvas.width = this.treeContainer.clientWidth
        this.canvas.height = this.treeContainer.clientHeight

        this.createPath()
      }
    }, 150)
  }

  private getCSSSubjectColor () {
    return this.getCSSColor(this.getSubjectColor(this.subject), 'lighten-4')
  }

  // -------------------------------------------- Draw ---------------------------------------------
  private drawHorizontalLine (destinationX: number) {
    this.ctx?.lineTo(destinationX, this.lineCurrentPos.y)
    this.lineCurrentPos.x = destinationX
  }

  private drawVertiсalLine (destinationY: number) {
    this.ctx?.lineTo(this.lineCurrentPos.x, destinationY)
    this.lineCurrentPos.y = destinationY
  }

  private drawTurningLine (directionY: 'top' | 'bottom') {
    let cp2x = 0
    let cp2y = 0
    let destinationX = 0
    /**
     * Находим вторую контрольную точку (первая совпадает с текущим положением)
     * NOTE: для себя назвал Координата Угла (т.к. это точка есть угол если бы не было закругления)
     * https://www.victoriakirst.com/beziertool/
     * https://developer.mozilla.org/ru/docs/Web/API/CanvasRenderingContext2D/bezierCurveTo
     */
    const destinationY = this.lineCurrentPos.y - this.turningSize

    if (directionY === 'bottom') {
      cp2x = this.lineCurrentPos.x >= this.canvas.width / 2
        ? this.lineCurrentPos.x + this.turningSize
        : this.lineCurrentPos.x - this.turningSize

      destinationX = cp2x
      cp2y = this.lineCurrentPos.y
    } else {
      cp2y = destinationY
      cp2x = this.lineCurrentPos.x

      destinationX = this.lineCurrentPos.x >= this.canvas.width / 2
        ? this.lineCurrentPos.x - this.turningSize
        : this.lineCurrentPos.x + this.turningSize
    }

    // NOTE: если нижний угол то Координата Угла неизменна по оси ординат если верхний то по оси абсцисс
    this.ctx?.bezierCurveTo(
      this.lineCurrentPos.x,
      this.lineCurrentPos.y,
      cp2x,
      cp2y,
      destinationX,
      destinationY,
    )

    this.lineCurrentPos.x = destinationX
    this.lineCurrentPos.y = destinationY
  }

  private drawTriangle () {
    if (this.ctx) {
      this.ctx.beginPath()

      // Note: эл-ты массива не всегда в верном порядке
      const nextLevel = this.refs.find(item => item.rank === this.currentRank + 1) ?? this.finalLevelRef
      const currentLevel = this.refs.find(item => item.rank === this.currentRank)

      if (currentLevel && nextLevel) {
        let isDirectionRight =
        currentLevel.chunkNumber === nextLevel.chunkNumber
          ? currentLevel.x < nextLevel.x
          : nextLevel.chunkNumber + 1 % 2 === 0

        if (isDirectionRight) {
          const currentX = nextLevel.x - nextLevel.ref.clientWidth / 2
          this.ctx.moveTo(currentX, nextLevel.y)
          this.ctx.lineTo(currentX - 15, nextLevel.y - 9)
          this.ctx.lineTo(currentX - 15, nextLevel.y + 9)
        } else {
          const currentX = nextLevel.x + nextLevel.ref.clientWidth / 2
          this.ctx.moveTo(currentX, nextLevel.y)
          this.ctx.lineTo(currentX + 15, nextLevel.y - 9)
          this.ctx.lineTo(currentX + 15, nextLevel.y + 9)
        }

        this.ctx.globalAlpha = 1
        this.ctx.fillStyle = this.getCSSSubjectColor()
        this.ctx.fill()
      }
    }
  }

  // -------------------------------------------- Refs ---------------------------------------------
  // Записываем реф финального уровня
  private initLastRef (ref: HTMLDivElement, rank: number) {
    const containerRect = this.treeContainer.getBoundingClientRect()
    const refRect = ref.getBoundingClientRect()

    this.finalLevelRef = {
      chunkNumber: -1,
      rank,
      ref,
      x: refRect.left - containerRect.left + ref.clientWidth / 2,
      y: refRect.top - containerRect.top + ref.clientHeight / 2,
    }

    this.ctx = this.canvas.getContext('2d')

    this.canvas.width = this.treeContainer.offsetWidth
    this.canvas.height = this.treeContainer.offsetHeight

    this.createPath()
  }

  // Аккумулируем все рефы отображаемых уровней
  private initRef (ref: HTMLDivElement, rank: number, chunkNumber: number) {
    const containerRect = this.treeContainer.getBoundingClientRect()
    const refRect = ref.getBoundingClientRect()

    this.refs.push({
      chunkNumber,
      rank,
      ref,
      x: refRect.left - containerRect.left + ref.clientWidth / 2,
      y: refRect.top - containerRect.top + ref.clientHeight / 2,
    })

    if (this.refs.length === this.chunkedLevels.flat().length) {
      // Сортируем по возврастанию уровня, на случай разного времени монтирования карточек в DOM
      this.refs.sort((a, b) => a.rank - b.rank)

      this.ctx = this.canvas.getContext('2d')

      this.canvas.width = this.treeContainer.offsetWidth
      this.canvas.height = this.treeContainer.offsetHeight

      this.createPath()
    }
  }

  // -------------------------------------------- Main ---------------------------------------------
  private createPath () {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.refs.forEach((item, index, array) => {
        if (index === 0) {
          this.lineCurrentPos = {
            x: item.x,
            y: item.y,
          }
        }

        this.ctx?.beginPath()
        this.ctx?.moveTo(this.lineCurrentPos.x, this.lineCurrentPos.y)

        if (this.ctx) {
          const showAll = this.showAll || this.finalLevel.rank === this.currentRank
          // Проверяем что уровни в массиве не закончились
          const nextRef = !array[index + 1] && showAll && this.finalLevelRef ? this.finalLevelRef : array[index + 1]

          if (nextRef) {
            // Проверяем что мы в одном чанке
            if (nextRef.chunkNumber === item.chunkNumber) {
              if (item.x < nextRef.x) {
                this.drawHorizontalLine(nextRef?.x - (item.ref.clientWidth / 2 + 20))
              } else if (item.x > nextRef.x) {
                this.drawHorizontalLine(nextRef?.x + (item.ref.clientWidth / 2 + 20))
              }
            } else {
              this.drawHorizontalLine(
                item.x >= this.canvas.width / 2 && !this.isSpecialCourse
                  ? this.canvas.width - (this.turningSize + this.strokeSize)
                  : (this.turningSize + this.strokeSize),
              )
              this.drawTurningLine('bottom')
              this.drawVertiсalLine(nextRef.y + this.turningSize)
              this.drawTurningLine('top')
              this.drawHorizontalLine(nextRef.x)
            }

            this.ctx.lineWidth = 3
            this.ctx.setLineDash([3, 3])
            this.ctx.strokeStyle = this.getCSSSubjectColor()
            this.ctx.globalAlpha = nextRef.rank >= this.currentRank + 2 ? 0.3 : 1
            this.ctx.stroke()
            this.ctx.closePath()
          }
        }
      })

      this.drawTriangle()
    }
  }
}
