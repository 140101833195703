










































import { Component, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

// Store
import MasterProgressModule from '@/store/modules/master/progress'
// Components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import { declension } from '@/utils/functions'

@Component({
  components: {
    CardWrapper,
  },
})
export default class ProgressCourseSidebarLessons extends Vue {
  private get courseID() {
    return this.$route.params.courseID
  }

  private get lessons() {
    if (MasterProgressModule.courseStatistic?.lessons) {
      return MasterProgressModule.courseStatistic.lessons.map((item) => {
        const date = DateTime.fromSQL(item.time)
        return {
          ...item,
          day: date.day,
          dayWeek: date.weekdayShort?.toLowerCase(),
          time: date.toFormat('HH:mm'),
        }
      })
    }

    return []
  }

  private get lessonsCounts () {
    return MasterProgressModule.courseStatistic?.lessonsCounts
  }

  private handleRedirectToLesson(lessonClickedID: number, groupClickedID: number) {
    const lessonID = lessonClickedID.toString()
    const groupID = groupClickedID.toString()

    this.$router.push({
      name: 'master.lessons.item',
      params: {
        groupID,
        lessonID,
      },
    })
  }

  private get watchedLessons () {
    if (MasterProgressModule.courseStatistic?.lessonsCounts) {
      return MasterProgressModule.courseStatistic.lessonsCounts.current
    }
    return 0
  }

  private get toWatchLessons () {
    if (MasterProgressModule.courseStatistic?.lessonsCounts) {
      return MasterProgressModule.courseStatistic.lessonsCounts.all
    }
    return 0
  }

  private get remainingLessonsCount() {
    return this.toWatchLessons - this.watchedLessons
  }

  private declension (n: number, t: string[]) {
    return declension(n, t)
  }
}
