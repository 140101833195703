import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScreenMixin extends Vue {
  protected get isMobileBreakpointReached() {
    return this.$vuetify.breakpoint.width < 768
    // return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
  }

  protected get isTabletopBreakPointReached() {
    return this.$vuetify.breakpoint.name === 'md'
  }
}
