




















import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

// components
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// store
import { CourseSubjectType } from '@/store/types/progress'

@Component({
  components: {
    ProgressSubjectChip,
  },
})
export default class CourseLevelCard extends mixins(MasterProgressCourseMixin) {
  @Prop({ required: true })
  levelInfo!: { name: string, rank: number, isAvailable?: boolean }

  @Prop({ required: true })
  subject!: CourseSubjectType

  @Prop({ default: false })
  isCurrentRank!: boolean

  @Prop({ default: 37 })
  finalLevel!: number

  @Ref() tagNode !: null | HTMLDivElement

  private isAvailable = this.levelInfo.isAvailable
  private name = this.isAvailable ? this.levelInfo.name : 'Следующий уровень'
  private rank = this.levelInfo.rank
  private maxChar = 13

  private get rankImageSrc () {
    return this.getRankIcon(this.levelInfo.rank, this.finalLevel === 2)
  }

  private get isFinalLevel () {
    return this.levelInfo.rank === this.finalLevel
  }

  private subjectColor = this.isAvailable ? this.getSubjectColor(this.subject) : 'green'
  private tagText = this.isAvailable ? this.getTagText(this.levelInfo.rank) : 'Недоступно'

  private get currentRankText() {
    return this.isCurrentRank ? this.isFinalLevel ? 'финальный' : 'текущий' : ''
  }

  private get tagType() {
    if (!this.isAvailable) return 'not-available'

    return this.isCurrentRank ? 'fill' : 'stroke-bcg'
  }

  private get isLargeWordInName () {
    return this.name.split(' ').findIndex(word => (word.length > 12)) + 1
  }

  private get cardLevelClasses() {
    return {
      [`card-${this.subject}`]: true,
      ['card-current']: this.isCurrentRank,
      ['card-unvailable']: !this.isAvailable,
      ['large-word-in-name']: this.isLargeWordInName,
    }
  }

  private showInfoLevel() {
    if (this.isAvailable)
      this.$bus.$emit('showLevelInfo', this.levelInfo.rank)
  }

  private mounted() {
    this.$emit('initRef', this.tagNode)
  }

  @Watch('levelInfo')
  observeLevelInfo() {
    this.isAvailable = this.levelInfo.isAvailable
    this.tagText = this.isAvailable ? this.getTagText(this.levelInfo.rank) : 'Недоступно'
    this.subjectColor = this.isAvailable ? this.getSubjectColor(this.subject) : 'white-dark'
    this.$emit('initRef', this.tagNode)
  }
}
