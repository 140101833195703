















































import { Component } from 'vue-property-decorator'
import { chunk } from 'lodash'
import { mixins } from 'vue-class-component'

// components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import ProgressCourseContentTree from '@/components/views/progress/course/content/ProgressCourseContent.Tree.vue'
import ProgressViewLevelModal from '@/components/modals/progress/ProgressViewLevelModal.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// mixins
import ScreenMixin from '@/mixins/ScreenMixin'
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// store
import MasterProgressModule from '@/store/modules/master/progress'
import MasterEducationModule from '@/store/modules/master/education'
import { CourseSubjectType } from '@/store/types/progress'
// Helpers
import { transformSubject } from '@/utils/functions'

export type ILevel = { name: string, rank: number, isAvailable?: boolean }

@Component({
  components: {
    CardWrapper,
    ProgressCourseContentTree,
    ProgressSubjectChip,
    ProgressViewLevelModal,
  },
})
export default class ProgressCourseContent extends mixins(MasterProgressCourseMixin, ScreenMixin) {
  private showAll = false
  private visibleViewLevelModal = false
  private currentLevelIndex = 0

  private get courseRanksProgress() {
    return MasterProgressModule.courseRanksProgress
  }

  private get subjectName () {
    const name = MasterEducationModule.masterGroups.find(item => item.course.id === +this.$route.params.courseID)?.subject.name

    return name ?? transformSubject(this.subject)
  }

  // То что с бэка прилетело
  private get ranksState() {
    return MasterProgressModule.courseRanksProgress
  }

  // Всё дерево целиком (полученные и неполученные уровни) в удобном виде
  private get levels (): ILevel[] {
    return this.ranksState.map(item => ({
      isAvailable: Number.isInteger(item.lessons) && Number.isInteger(item.exercises),
      name: item.name,
      rank: item.position,
    }))
  }

  // Текущий уровень
  private get currentRank () {
    return [...this.levels].reverse().find(item => item?.isAvailable)?.rank ?? 0
  }

  private get allCompleted () {
    return this.finalLevel?.rank === this.currentRank
  }

  private get isSpecialCourse () {
    return this.ranksState?.length === 2
  }

  // Размер чанка (строки) в зависимости от ширины экрана
  private get chunkSize () {
    return this.isMobileBreakpointReached ? 2 : 3
  }

  private get chunkedArray () {
    if (this.showAll) {
      // Возвращаем отраженную копию массива со всеми уровнями кроме финального, разбитую на чанки
      // NOTE: Реверс необходим для правильного заполнения строк сверху вниз
      return chunk([...this.levels.slice(0, -1)].reverse(), this.chunkSize)
    } else if (this.allCompleted) {
      return chunk(
        [...this.levels.filter(item => {
          return item.rank <= this.currentRank + 1 && item.rank !== this.finalLevel.rank
        })].reverse(),
        this.chunkSize,
      )
    } else {
      // Возвращаем отраженную копию массива со всеми полученными уровнями + следующий недоступный, разбитую на чанки
      // NOTE: Реверс необходим для правильного заполнения строк сверху вниз
      return chunk(
        [...this.levels.filter(item => {
          return item.rank <= this.currentRank + 1
        })].reverse(),
        this.chunkSize,
      )
    }
  }

  private get chunkedSpecialArray () {
    if (this.$vuetify.breakpoint.width < 600) {
      return chunk(this.levels, 1)
    }

    return chunk([...this.levels].reverse(), 2)
  }

  // Объект финального уровня
  private get finalLevel () {
    return this.levels?.slice(-1)[0]
  }

  // Учебный предмет для определения цвета
  private get subject (): CourseSubjectType {
    return this.ranksState.length && this.ranksState[0].course
      ? this.ranksState[0].course.subject as CourseSubjectType
      : CourseSubjectType.HISTORY
  }

  private get subjectColor() {
    return this.getSubjectColor(this.subject)
  }

  private toggleAll() {
    this.showAll = !this.showAll
  }

  private showLevelInfo(level: number) {
    this.currentLevelIndex = level - 1
    this.visibleViewLevelModal = true
  }

  private mounted() {
    this.$bus.$on('showLevelInfo', this.showLevelInfo)
  }

  private destroyed() {
    this.$bus.$off('showLevelInfo', this.showLevelInfo as any)
  }
}
