import Vue from 'vue'
import { Component } from 'vue-property-decorator'

// Store
import MasterProgressModule from '@/store/modules/master/progress'

export interface IProgress {
  courseId: number,
  subject: string,
  name: string,
  description: string,
  hasStarted: boolean,
  rank: number,
  fullDescription: string,
}

export interface IProgressExtended extends IProgress {
  lessonsToComplete: number,
  exercisesToComplete: number,
}

@Component
export default class MasterProgressMixin extends Vue {

  protected get currentCourse() {
    return MasterProgressModule.currentCourse
  }

  protected get progress(): IProgress[] {
    return MasterProgressModule.coursesFull
  }
}
