







































import { Component, Prop } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import ProgressCourseStatsBlock from '@/components/views/progress/course/sidebar/ProgressCourseStatsBlock.vue'
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
import MasterProgressModule from '@/store/modules/master/progress'
import { CourseSubjectType } from '@/store/types/progress'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    ProgressCourseStatsBlock,
    ProgressSubjectChip,
  },
})
export default class ProgressStatsModal extends MasterProgressCourseMixin {
  @Prop({ default: false })
  private visible!: boolean

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private get dialogWidth () {
    if (this.$vuetify.breakpoint.width > 1365) {
      return 600
    } else {
      return 500
    }
  }

  private get color() {
    const cssVariableName = '--v-teal-lighten-3'

    return getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariableName)
  }

  private get isSpecial () {
    return MasterProgressModule.courseStatistic?.currentRank?.course?.type === 'special' ?? false
  }

  private get stats() {
    return MasterProgressModule.courseStatistic
  }

  private get src() {
    return this.getRankIcon(this.stats?.currentRank.position as number, this.isSpecial)
  }

  private get rankText() {
    return this.getTagText(this.stats?.currentRank.position as number)
  }

  private get rankName() {
    return this.stats?.currentRank.name
  }

  private get percent() {
    return this.stats?.percent
  }

  private get subjectColor () {
    return this.stats ? this.getSubjectColor(this.stats.currentRank.course.subject as CourseSubjectType) : 'blue'
  }
}
