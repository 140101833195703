




































import { Component, Vue } from 'vue-property-decorator'

// Store
import MasterProgressModule from '@/store/modules/master/progress'
// Components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import ProgressCourseSidebarStats from '@/components/views/progress/course/sidebar/ProgressCourseSidebar.Stats.vue'
import ProgressCourseSidebarExercises from '@/components/views/progress/course/sidebar/ProgressCourseSidebar.Exercises.vue'
import ProgressCourseSidebarLessons from '@/components/views/progress/course/sidebar/ProgressCourseSidebar.Lessons.vue'
import ProgressCourseStatsBlock from '@/components/views/progress/course/sidebar/ProgressCourseStatsBlock.vue'
import CourseExplanationPlanStudy from '@/components/modals/courses/CourseExplanationPlanStudy.vue'

@Component({
  components: {
    CardWrapper,
    CourseExplanationPlanStudy,
    ProgressCourseSidebarExercises,
    ProgressCourseSidebarLessons,
    ProgressCourseSidebarStats,
    ProgressCourseStatsBlock,
  },
})
export default class ProgressCourseSidebar extends Vue {
  private visible = false

  private get percentOfCourseProgress() {
    if (MasterProgressModule.courseStatistic) {
      return MasterProgressModule.courseStatistic.percent
    }
    return 0
  }

  private get nextLevelValue() {
    if (MasterProgressModule.courseStatistic) {
      return MasterProgressModule.courseStatistic.currentRank.position + 1
    }
    return -1
  }

  private get countRanks() {
    return MasterProgressModule.courseRanksProgress.length
  }

  private get currentRank() {
    if (MasterProgressModule.courseStatistic) {
      return MasterProgressModule.courseStatistic.currentRank.position
    }
    return 0
  }

  private get isNextLevelAvailableToReach() {
    return this.percentOfCourseProgress !== 100 && this.currentRank < this.countRanks
  }

  private showTip() {
    this.visible = true
  }
}
